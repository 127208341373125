
@media only screen and (max-width: 600px){
 
  .container,
  #interactive.viewport {
    display: flex;
    justify-content: center;
    background-color: black;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }

  #interactive.viewport canvas,
  video {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    height: 100vh;
    overflow: hidden;
  }

  #interactive.viewport canvas.drawingBuffer,
  video.drawingBuffer {
    height: 100vh;
    overflow: hidden;
  }

  #barcode-container {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .overlay {
    /* background-color: whitesmoke; */
    opacity: .7;
    width: 50%;
    z-index: 1;
  }

  /* .overlay-text {
    position: absolute;
    opacity: .7;
    z-index: 1;
    color: 'black';
  } */
 
}

@media only screen and (min-width: 600px){
 
    .container,
    #interactive.viewport {
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: black;
    }
  
    #interactive.viewport canvas,
    video {
      width: 100%;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
    }
  
    #interactive.viewport canvas.drawingBuffer,
    video.drawingBuffer {
      width: 100%;
    }
  
    .overlay {
      position: absolute;
      opacity: .7;
      width: 50%;
      z-index: 1;
      margin-top: 7%;
    }
  
    /* .overlay-text {
      position: absolute;
      opacity: .7;
      z-index: 1;
      color: 'black';
    } */
   
  }


@media only screen and (min-width: 1366px){

.container,
#interactive.viewport {
  width: 100%;
      display: flex;
    justify-content: center;
}

#interactive.viewport canvas,
video {
  width: 30%;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
}

#interactive.viewport canvas.drawingBuffer,
video.drawingBuffer {
  width: 30%;
}

  .overlay {
    position: absolute;
    opacity: .7;
    width: 15%;
    z-index: 1;
    margin-top: 3%;
  }

  /* .overlay-text {
    position: absolute;
    opacity: .7;
    z-index: 1;
    color: 'black';
  } */
 
}

